import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import { Card, CardContent } from "@material-ui/core";
import CardHeader from "../../../../components/cardHeader";
import { TitleValueCol } from "../../../../components/titleValueCol";
import TitleValue from "../../../../components/titleValue";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: "1em",
  },
}));

export const MachineDriverCard = ({ title, name, phone, email }: { title: string, name: string, phone: string, email?: string }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader icon={<Person />} title={title}></CardHeader>
      <CardContent>
        <TitleValueCol>
          <TitleValue title="Navn" value={name} />
          <TitleValue title="Telefon" value={phone} type="phone" />
          {email && <TitleValue title="Epost" value={email} type="email" />}
        </TitleValueCol>
      </CardContent>
    </Card>
  );
};
