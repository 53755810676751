import { IMachine } from './../api/types/machine';
import IVehicleGroup from "../api/types/vehicleGroup";
import { getIndex } from './array';

export const getMachineCategory = (machines: IMachine[]) => {
    const cat: IVehicleGroup = { name: 'Maskin', value: 'machine', mainCategories: [] };
    for (let i = 0; i < machines.length; i += 1) {
      const machine = machines[i];

      // main categories
      if (!cat.mainCategories.some(mc => mc.value === machine.mainCategoryName)) {
        cat.mainCategories.push({ name: machine.mainCategoryName, value: machine.mainCategoryName, subCategories: [] });
      }
      const m = getIndex(cat.mainCategories, c => c.value === machine.mainCategoryName);
      if (m === null) continue;

      // subcategories
      const s = getIndex(cat.mainCategories[m].subCategories, c => c.value === machine.subCategoryName);
      if (s === null) {
        cat.mainCategories[m].subCategories.push({ name: machine.subCategoryName, value: machine.subCategoryName });
      }
    }
    return cat;
}
