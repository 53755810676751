import React from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { BACKGROUND_COLOR } from "../../../../layout/contants";
import { MachineSearch } from "./MachineSearch";
import { MachineInfo } from "./MachineInfo";
import { MachinesTable } from "./MachineTable";
import { useMachineState } from "../../../../context/machineContext";

const useStyles = makeStyles((theme: Theme) => ({
  sidesheetWrapper: {
    backgroundColor: BACKGROUND_COLOR,
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  sidesheetContent: {
    overflowY: "auto",
    flex: 1,
  },
}));

export const MachineSidesheet = () => {
  const { selectedMachine } = useMachineState();

  const classes = useStyles();

  return (
    <div className={classes.sidesheetWrapper}>
      <MachineSearch />
      <div className={classes.sidesheetContent}>
        {selectedMachine && <MachineInfo />}
        {!selectedMachine && <MachinesTable />}
      </div>
    </div>
  );
};

