import React from "react";

import {
  DRAWER_WIDTH,
  PRIMARY_COLOR,
  TITLEBAR_HEIGHT
} from "./contants";
import {
  useSidesheetDispatch,
  useSidesheetState,
} from "../context/sidesheetContext";

import {
  Drawer,
  IconButton,
  makeStyles,
  Theme
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { SET_IS_SIDESHEET_OPEN, SET_SELECTED_TAB } from "../context/actions/sidesheetActions";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from "../components/tabPanel";
import { VehicleSidesheet } from "../pages/map/components/vehicle/vehicleSidesheet";
import { ProjectSidesheet } from "../pages/map/components/project/projectSidesheet";
import { MachineSidesheet } from "../pages/map/components/machine/MachineSidesheet";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    backgroundColor: "#F5F5F5",
    overflow: "hidden",
    display: "flex",
    height: "100%",
  },
  drawerHeader: {
    height: `${TITLEBAR_HEIGHT}px`,
    padding: "1em",
    backgroundColor: PRIMARY_COLOR,
    display: "flex",
    alignItems: "center",
  },
  drawerContent: {
    flex: 1,
    height: `calc(100% - ${TITLEBAR_HEIGHT}px)`,
  },
  logo: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    flex: 0,
    color: "#fff",
  },
  tab: {
    minWidth: 50,
  }
}));

export const Sidesheet = () => {
  const classes = useStyles();

  const { isOpen, selectedTab = 'vehicle' } = useSidesheetState();
  const sidesheetDispatch = useSidesheetDispatch();

  const handleSidebarCloseOnClick = () => {
    sidesheetDispatch({ type: SET_IS_SIDESHEET_OPEN, payload: false });
  };

  const switchTab = (newTab: typeof selectedTab) => {
    sidesheetDispatch({ type: SET_SELECTED_TAB, payload: newTab });
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <div className={classes.logo}>
          <img alt="Stangeland Maskin Logo" src="./logo-text-neg.png" />
        </div>
        <IconButton
          aria-label="clear"
          onClick={handleSidebarCloseOnClick}
          className={classes.iconButton}
        >
          <Close />
        </IconButton>
      </div>
      <Tabs
        value={selectedTab}
        onChange={(_, v) => switchTab(v)}
        aria-label="simple tabs example"
        variant="fullWidth"
        indicatorColor="primary"
      >
        <Tab className={classes.tab} label="Kjøretøy" value={'vehicle'} />
        <Tab className={classes.tab} label="Prosjekt" value="project" />
        <Tab className={classes.tab} label="Maskiner" value="machine" />
      </Tabs>
      <TabPanel<typeof selectedTab> value={selectedTab} index={'vehicle'}>
        <div className={classes.drawerContent}>
          <VehicleSidesheet />
        </div>
      </TabPanel>
      <TabPanel<typeof selectedTab> value={selectedTab} index={'project'}>
        <div className={classes.drawerContent}>
          <ProjectSidesheet />
        </div>
      </TabPanel>
      <TabPanel<typeof selectedTab> value={selectedTab} index={'machine'}>
        <div className={classes.drawerContent}>
          <MachineSidesheet />
        </div>
      </TabPanel>
    </Drawer>
  );
};

export default Sidesheet;
