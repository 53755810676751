import React, { useState } from "react";
import { useAppState } from "../context/appContext";
import { useAuthState } from "../context/authContext";
import { Avatar, makeStyles, Menu, MenuItem, Theme } from "@material-ui/core";

import {
  TITLEBAR_HEIGHT,
  PRIMARY_COLOR,
  DRAWER_WIDTH,
} from "../layout/contants";
import {
  useSidesheetDispatch,
  useSidesheetState,
} from "../context/sidesheetContext";
import {
  SET_IS_SIDESHEET_OPEN,
  SET_SIDESHEET_CONTENT,
} from "../context/actions/sidesheetActions";
import { FilterCenterFocus, Label, LabelOff } from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";

import MapFilters from "./mapFilters";

import { useVehicleDispatch, useVehicleState } from "../context/vehicleContext";
import { SET_SHOW_REG_NUMBER } from "../context/actions/vehicleActions";
import { useMapDispatch, useMapState } from "../context/mapContext";
import { SET_CENTER, SET_ZOOM } from "../context/actions/mapActions";
import clsx from "clsx";
import { VehicleSidesheet } from "../pages/map/components/vehicle/vehicleSidesheet";

const useStyles = makeStyles((theme: Theme) => ({
  titleBar: {
    width: "100%",
    height: TITLEBAR_HEIGHT,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5em",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  titleBarLeft: {
    flex: 0,
  },
  titlebarMiddle: {
    flex: 1,
  },
  titleBarRight: {
    flex: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    borderRadius: "100%",
    background: PRIMARY_COLOR,
    margin: "0.5em",
    cursor: "pointer",
    "&:hover": {
      background: " #142a42",
    },
  },
  iconButtonDisabled: {
    backgroundColor: "gray",
  },
  filterInput: {
    backgroundColor: "#fff",
    paddingLeft: "0.5em",
    paddingTop: "0.25em",
    paddingBottom: "0.25em",
  },
  logo: {
    width: "160px",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },

  filterButton: {
    margin: theme.spacing(1),
    width: 170,
  },
  formControl: {
    //color: "#142a42",
  },
  titleBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export const TitleBar = () => {
  const { account, logout } = useAuthState();
  const { isLoading } = useAppState();
  const { showRegNumber } = useVehicleState();
  const mapDispatch = useMapDispatch();
  const { defaultZoom, defaultCenter } = useMapState();
  const { isOpen } = useSidesheetState();
  const vehicleDispatch = useVehicleDispatch();
  const sidesheetDispatch = useSidesheetDispatch();
  const classes = useStyles();
  const [profileMenuAnchor, setProfileMenuAnchor] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const isProfileMenuOpen = Boolean(profileMenuAnchor);

  const handleProfileOnClick = (event: any) => {
    setProfileMenuAnchor(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchor(null);
  };

  const handleSignOut = () => {
    handleProfileMenuClose();
    logout();
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={profileMenuAnchor}
      anchorOrigin={{ vertical: "center", horizontal: "right" }}
      id="profile-menu"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleSignOut}>Logg ut</MenuItem>
    </Menu>
  );

  const handleShowSidesheetOnClick = () => {
    sidesheetDispatch({ type: SET_IS_SIDESHEET_OPEN, payload: true });
    sidesheetDispatch({
      type: SET_SIDESHEET_CONTENT,
      payload: <VehicleSidesheet />,
    });
  };

  const handleSetMapHome = () => {
    mapDispatch({ type: SET_CENTER, payload: defaultCenter });
    mapDispatch({ type: SET_ZOOM, payload: defaultZoom });
  };

  const handleShowRegNumberOnClick = () => {
    vehicleDispatch({
      type: SET_SHOW_REG_NUMBER,
      payload: !showRegNumber,
    });
  };

  return (
    <>
      <div
        className={clsx(classes.titleBar, {
          [classes.titleBarShift]: isOpen,
        })}
      >
        <div className={classes.titleBarLeft}>
          {!isOpen && (
            <Avatar
              className={classes.iconButton}
              onClick={handleShowSidesheetOnClick}
            >
              <MenuIcon />
            </Avatar>
          )}
        </div>

        <div className={classes.titlebarMiddle}></div>

        <div className={classes.titleBarRight}>
          {!isLoading && account && <MapFilters />}
          <Avatar
            title="Gå tilbake til startpunkt"
            className={classes.iconButton}
            onClick={handleSetMapHome}
          >
            <FilterCenterFocus />
          </Avatar>
          <Avatar
            title={showRegNumber ? "Vis reg. nummer" : "Vis navn"}
            className={clsx(classes.iconButton, {
              [classes.iconButtonDisabled]: !showRegNumber,
            })}
            onClick={handleShowRegNumberOnClick}
          >
            {showRegNumber ? <Label /> : <LabelOff />}
          </Avatar>
          {account !== null && (
            <Avatar
              title="Profil"
              className={classes.iconButton}
              onClick={handleProfileOnClick}
            >
              {account.name?.charAt(0)}
            </Avatar>
          )}
        </div>
      </div>
      {renderProfileMenu}
    </>
  );
};

export default TitleBar;
