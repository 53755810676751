import axios from "axios";

import IAppConfig from "./types/appConfig";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

export const getAppConfig = async (
  getAccessToken: () => Promise<string | null>
): Promise<IAppConfig | null> => {
  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      throw new Error("Unable to get access token");
    }

    const response = await client.get<IAppConfig>("/config/app", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("getAppConfig", error);
    return null;
  }
};
