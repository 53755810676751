import React from "react";

import IVehicle from "../../../../api/types/vehicle";
import { makeStyles } from "@material-ui/core";
import {
  INACTIVE_COLOR,
  PRIMARY_COLOR,
  PRIMARY_HOVER_COLOR
} from "../../../../layout/contants";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: (props: any) => `${props.size}px`,
    width: (props: any) => `${props.size}px`,
    marginLeft: (props: any) => `-${props.size / 2}px`,
    marginTop: (props: any) => `-${props.size / 2}px`,
    "&:hover": {
      cursor: "pointer",
      "& $circle": {
        fill: PRIMARY_HOVER_COLOR,
      },
      "& $arrow": {
        fill: PRIMARY_HOVER_COLOR,
      },
      "& $text": {
        backgroundColor: "#AAA",
        zIndex: 10,
      },
    },
  },
  text: {
    position: "absolute",
    marginTop: (props: any) => `${props.size + 25}px`,
    color: "#000",
    padding: "5px",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    whiteSpace: "nowrap",
  },
  selected: {
    border: "2px solid #1b628e",
    boxShadow: "0px 0px 6px 1px #1b628e88",
    backgroundColor: "#CCC",
    color: 'black',
  },
  vehicle: {
    color: "white",
  },
  arrow: {
    fill: (props: any) => (props.isActive ? PRIMARY_COLOR : INACTIVE_COLOR),
    stroke: "#fff",
  },
  circle: {
    fill: (props: any) => (props.isActive ? PRIMARY_COLOR : INACTIVE_COLOR),
    stroke: "#fff",
  },
});

export const VehicleMapPointer = ({
  heading,
  size = 35,
  vehicle,
  showRegNumber,
  selected = false,
  onClick = () => null,
}: {
  heading: number;
  size?: number;
  vehicle: IVehicle;
  showRegNumber: boolean;
  selected?: boolean;
  onClick?: (machine: IVehicle) => void;
}) => {
  const classes = useStyles({
    size: size,
    isActive: true,
  });

  const Arrow = () => {
    return (
      <path
        d="m147.99999,82l52.00001,-72l52.00001,72l-104.00001,0l-0.00001,0z"
        strokeWidth="5"
        strokeLinejoin="round"
        transform={`rotate(${heading} 200,200)`}
        className={classes.arrow}
        filter="url(#drop-shadow)"
      ></path>
    );
  };

  const WrapperCircle = () => {
    return (
      <circle
        strokeWidth="15"
        r={100}
        cy={200}
        cx={200}
        className={classes.circle}
        filter="url(#drop-shadow)"
      ></circle>
    );
  };

  return (
    <div className={classes.wrapper} onClick={() => onClick(vehicle)}>
      <svg width={50} height={50} viewBox="0 0 400 400">
        <defs>
          <filter id="drop-shadow" x="0" y="0" width="400" height="400">
            <feOffset result="offOut" in="SourceAlpha" dx="20" dy="20" />
            <feColorMatrix
              result="matrixOut"
              in="offOut"
              type="matrix"
              values=" 0.49 0 0 0 0 0 0.49 0 0 0 0 0 0.49 0 0 0 0 0 0.5 0"
            />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="15" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>
        </defs>
        <g>
          <Arrow />
          <WrapperCircle />
        </g>
      </svg>

      <span className={classes.text + (selected ? ' ' + classes.selected : '')}>
        {showRegNumber
          ? vehicle.registrationNumber ?? vehicle.vin
          : vehicle.nickname ?? vehicle.vin}
      </span>
    </div>
  );
};

export default VehicleMapPointer;
