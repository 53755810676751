import axios from "axios";
import IProject from './types/project';

const client = axios.create({
  baseURL: process.env.REACT_APP_PROSJEKT_API_BASE_URL,
  responseType: "json",
});

export const getProjects = async (
  getAccessToken: () => Promise<string | null>
): Promise<IProject[] | null> => {
  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      throw new Error("Unable to get access token");
    }

    const response = await client.get<IProject[]>("/api/v1.0/projects", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data.filter(d => (
      d.latitude
      && d.longitude
      && parseFloat(d.latitude.replace(',', '.'))
      && parseFloat(d.longitude.replace(',', '.'))
    ));
  } catch (error) {
    console.error("getAppConfig", error);
    return null;
  }
};
