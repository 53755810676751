import React from "react";

import { Box, makeStyles } from "@material-ui/core";

interface ILoaderProps {
  size: number;
}

const useStyles = makeStyles({
  loaderLogo: {
    width: (props: ILoaderProps) => `${props.size}em`,
    pointerEvents: "none",
    animation: "$AppLogoSpin 1s infinite",
  },
  "@keyframes AppLogoSpin": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(0.8)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  loaderRoot: {
    height: "100%",
    width: "100%",
    zIndex: 10000,
  },
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export const Loader = ({ size }: { size: number }) => {
  const classes = useStyles({ size: size });

  return (
    <Box display="flex" className={classes.loaderRoot}>
      <Box m="auto" className={classes.loaderContainer}>
        <img
          src={"./symbol-pos.png"}
          alt="Laster..."
          className={classes.loaderLogo}
        />
      </Box>
    </Box>
  );
};

export default Loader;
