import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import Action from "../types/action";
import {
  SET_IS_LOADING,
  SET_TITLE,
  SET_APP_CONFIG,
} from "./actions/appActions";
import IAppState from "./types/appState";
import { getAppConfig } from "../api/configApi";
import { useAuthState } from "./authContext";

const initialState: IAppState = {
  title: "Kartløsning",
  isLoading: false,
  appConfig: null,
};

const AppStateContext = createContext<IAppState>(initialState);
const AppDispatchContext = createContext<Dispatch<Action>>(() => {});

const appReducer = (state: IAppState, action: Action): IAppState => {
  switch (action.type) {
    case SET_TITLE: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case SET_APP_CONFIG: {
      return {
        ...state,
        appConfig: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const { account, getAccessToken } = useAuthState();

  useEffect(() => {
    if (account) {
      const fetchAppConfig = async () => {
        dispatch({ type: SET_IS_LOADING, payload: true });
        const appConfig = await getAppConfig(getAccessToken);
        dispatch({ type: SET_APP_CONFIG, payload: appConfig });
        dispatch({ type: SET_IS_LOADING, payload: false });
      };

      fetchAppConfig();
    }
  }, [account, getAccessToken]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export const useAppState = (): IAppState => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a AppProvider");
  }
  return context;
};

export const useAppDispatch = (): Dispatch<Action> => {
  const context = useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error("useAppDispatch must be used within a AppProvider");
  }
  return context;
};
