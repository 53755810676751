// Sizes
export const TITLEBAR_HEIGHT = 65;
export const TOOLBAR_HEIGHT = 65;
export const DRAWER_WIDTH = 400;

// Colors
export const PRIMARY_COLOR = "#1b628e";
export const PRIMARY_HOVER_COLOR = "#006ecf";
export const INACTIVE_COLOR = "#6b6b6b";
export const BORDER_COLOR = "#e0e0e0";
export const BACKGROUND_COLOR = "#F5F5F5";
export const PRIMARY_PROJECT_COLOR = "#149e42";
export const PRIMARY_PROJECT_HOVER_COLOR = "#16b74b";
export const PRIMARY_MACHINE_COLOR = "#59149f";
export const PRIMARY_MACHINE_HOVER_COLOR = "#8946d6";
