export const SET_VEHICLES = "vehicle-set-vehicles";
export const SET_SELECTED_VEHICLE = "vehicle-set-selected-vehicle";
export const SET_IS_LOADING_VEHICLE_GEOLOCATION =
  "vehicle-set-is-loading-vehicle-geolocation";
export const SET_SELECTED_VEHICLE_GEOLOCATION =
  "vehicle-set-selected-vehicle-geolocation";
export const SET_VEHICLE_GROUPS = "vehicle-set-groups";
export const SET_ROAD_TYPES = 'vehicle-set-road-types';
export const SET_SELECTED_VEHICLE_GROUPS = "vehicle-set-selected-groups";
export const SET_SELECTED_MAIN_CATEGORIES =
  "vehicle-set-selected-main-categories";
export const SET_SELECTED_SUB_CATEGORIES =
  "vehicle-set-selected-sub-categories";
export const SET_SELECTED_ROAD_TYPES = 'vehicle-set-selected-road-types';
export const SET_SHOW_REG_NUMBER = "vehicle-set-show-reg-number";
export const SET_SEARCH_TEXT = "vehicle-set-search-text";
