import React from "react";

import { useVehicleState } from "../../../../context/vehicleContext";
import { makeStyles, Theme } from "@material-ui/core";
import { BACKGROUND_COLOR } from "../../../../layout/contants";
import VehicleSearch from "./vehicleSearch";
import VehicleInfo from "./vehicleInfo";
import VehiclesTable from "./vehiclesTable";

const useStyles = makeStyles((theme: Theme) => ({
  sidesheetWrapper: {
    backgroundColor: BACKGROUND_COLOR,
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  sidesheetContent: {
    overflowY: "auto",
    flex: 1,
  },
}));

export const VehicleSidesheet = () => {
  const { selectedVehicle } = useVehicleState();

  const classes = useStyles();

  return (
    <div className={classes.sidesheetWrapper}>
      <VehicleSearch />
      <div className={classes.sidesheetContent}>
        {selectedVehicle && <VehicleInfo />}
        {!selectedVehicle && <VehiclesTable />}
      </div>
    </div>
  );
};
