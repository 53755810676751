import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import IVehicleState from "./types/vehicleState";
import Action from "../types/action";
import {
  SET_VEHICLES,
  SET_SELECTED_VEHICLE,
  SET_SELECTED_VEHICLE_GEOLOCATION,
  SET_VEHICLE_GROUPS,
  SET_SELECTED_VEHICLE_GROUPS,
  SET_SELECTED_MAIN_CATEGORIES,
  SET_SELECTED_SUB_CATEGORIES,
  SET_IS_LOADING_VEHICLE_GEOLOCATION,
  SET_SHOW_REG_NUMBER,
  SET_SEARCH_TEXT,
  SET_SELECTED_ROAD_TYPES,
  SET_ROAD_TYPES,
} from "./actions/vehicleActions";
import { getVehicles } from "../api/vehicleApi";
import { useAuthState } from "./authContext";
import IMainCategory from "../api/types/mainCategory";
import ISubCategory from "../api/types/subCategory";
import useLocalStorage from "../utility/useLocalStorage";
import IVehicleGroup from "../api/types/vehicleGroup";
import IRoadType from "../api/types/roadType";
import { getMachineCategory } from "../functions/machine";
import { useMachineState } from "./machineContext";
import { getMainCategories, getSubCategories } from "../functions/array";
import IVehicle from '../api/types/vehicle';
import { computeMainCategory, computeSubcategory } from "../functions/computeCategories";
import { getVehicleCategory } from "../functions/vehicle";

const initialState: IVehicleState = {
  vehicles: [],
  selectedVehicle: null,
  isLoadingVehicleGeolocation: false,
  selectedVehicleGeolocation: null,
  vehicleGroups: [],
  mainCategories: [],
  subCategories: [],
  roadTypes: [],
  selectedVehicleGroups: [],
  selectedMainCategories: [],
  selectedSubCategories: [],
  selectedRoadTypes: [],
  showRegNumber: false,
  searchText: "",
};

const vehicleUpdateFrequency = 30;

const VehicleStateContext = createContext<IVehicleState>(initialState);
const VehicleDispatchContext = createContext<Dispatch<Action>>(() => { });

const vehicleReducer = (
  state: IVehicleState,
  action: Action
): IVehicleState => {
  switch (action.type) {
    case SET_VEHICLES: {
      const vehicles = action.payload as IVehicle[];
      let selectedVehicle = state.selectedVehicle?.vin
        ? vehicles.find(v => v.vin === state.selectedVehicle?.vin) || null
        : null;
      return {
        ...state,
        vehicles,
        selectedVehicle,
      };
    }
    case SET_SELECTED_VEHICLE: {
      return {
        ...state,
        selectedVehicle: action.payload,
        searchText: action.payload
          ? action.payload.nickname ??
          action.payload.registrationNumber ??
          action.payload.vin
          : state.searchText,
      };
    }
    case SET_IS_LOADING_VEHICLE_GEOLOCATION: {
      if (!state.selectedVehicle) {
        return state;
      }
      return {
        ...state,
        isLoadingVehicleGeolocation: action.payload,
      };
    }
    case SET_SELECTED_VEHICLE_GEOLOCATION: {
      if (!state.selectedVehicle) {
        return state;
      }
      return {
        ...state,
        selectedVehicleGeolocation: action.payload,
      };
    }
    case SET_VEHICLE_GROUPS: {
      const mainCategories = getMainCategories(state.selectedVehicleGroups || []);
      const subCategories = getSubCategories(state.selectedMainCategories || []);
      return {
        ...state,
        vehicleGroups: action.payload,
        mainCategories,
        subCategories,
      };
    }
    case SET_ROAD_TYPES: {
      return {
        ...state,
        roadTypes: action.payload,
      };
    }
    case SET_SELECTED_VEHICLE_GROUPS: {

      const mainCat = computeMainCategory(
        action.payload,
        state.selectedVehicleGroups || [],
        state.selectedMainCategories || [],
      );

      const subCat = computeSubcategory(
        mainCat.selectedMainCategories,
        state.selectedMainCategories || [],
        state.selectedSubCategories || [],
      );

      return {
        ...state,
        ...subCat,
        ...mainCat,
        selectedVehicleGroups: action.payload,
      };
    }
    case SET_SELECTED_MAIN_CATEGORIES: {
      const subCat = computeSubcategory(
        action.payload,
        state.selectedMainCategories || [],
        state.selectedSubCategories || [],
      );

      return {
        ...state,
        ...subCat,
        selectedMainCategories: action.payload,
      };
    }
    case SET_SELECTED_SUB_CATEGORIES: {
      return {
        ...state,
        selectedSubCategories: action.payload,
      };
    }
    case SET_SELECTED_ROAD_TYPES: {
      return {
        ...state,
        selectedRoadTypes: action.payload,
      };
    }
    case SET_SHOW_REG_NUMBER: {
      return {
        ...state,
        showRegNumber: action.payload,
      };
    }
    case SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const VehicleProvider = ({ children }: { children: JSX.Element }) => {
  // Create reference to persistant storage and set default values
  const [
    persistentSelectedVehicleGroups,
    setPersistentSelectedVehicleGroups,
  ] = useLocalStorage<IVehicleGroup[]>("selected-vehicle-groups", []);
  const [
    persistentSelectedMainCategories,
    setPersistentSelectedMainCategories,
  ] = useLocalStorage<IMainCategory[]>("selected-main-categories", []);
  const [
    persistentSelectedSubCategories,
    setPersistentSelectedSubCategories,
  ] = useLocalStorage<ISubCategory[]>("selected-sub-categories", []);
  const [
    persistentSelectedRoadTypes,
    setPersistentSelectedRoadTypes,
  ] = useLocalStorage<IRoadType[]>("selected-road-types", []);

  // Set values from persistent storage
  const [state, dispatch] = useReducer(vehicleReducer, {
    ...initialState,
    selectedVehicleGroups: persistentSelectedVehicleGroups,
    selectedMainCategories: persistentSelectedMainCategories,
    selectedSubCategories: persistentSelectedSubCategories,
    selectedRoadTypes: persistentSelectedRoadTypes,
  });
  const { account, getAccessToken } = useAuthState();

  const { machines } = useMachineState();

  // Retrieve state values
  const {
    vehicles,
    vehicleGroups,
    selectedVehicleGroups,
    selectedMainCategories,
    selectedSubCategories,
    selectedRoadTypes,
  } = state;

  // Fetch vehicles and set up fetch every {vehicleUpdateFrequency} seconds
  useEffect(() => {
    if (!account) return;

    const fetchVehicles = async () => {
      const vehicles = await getVehicles(getAccessToken);
      if (!vehicles) return;
      dispatch({ type: SET_VEHICLES, payload: vehicles, });
    };

    fetchVehicles();

    const ref = setInterval(fetchVehicles, 1000 * vehicleUpdateFrequency);
    return () => clearInterval(ref);
  }, [account, getAccessToken]);

  // Fetch vehicle groups
  useEffect(() => {
    if (!account) return;

    const fetchVehicleGroups = async () => {
      if (vehicleGroups.length > 0) return; // Already fetched
      if(machines.length === 0 || vehicles.length === 0) return;

      const vehicleGroup = getVehicleCategory(vehicles)
      const machineGroup = getMachineCategory(machines);

      if (!vehicleGroup) return;

      const payload = [
        vehicleGroup,
        machineGroup,
        { name: 'Prosjekt', value: 'project', mainCategories: [] }
      ];

      dispatch({ type: SET_VEHICLE_GROUPS, payload });
      if (selectedVehicleGroups.length <= 0) {
        dispatch({ type: SET_SELECTED_VEHICLE_GROUPS, payload })
      }
    };

    fetchVehicleGroups();
  }, [account, selectedVehicleGroups, vehicleGroups, vehicles, machines]);

  // Set road types
  useEffect(() => {
    // Hard coded for now, but easy to change in future
    dispatch({
      type: SET_ROAD_TYPES,
      payload: [
        { value: '60', name: '60 tonn' },
      ]
    })
  }, []);

  // === Update local storage with new values ===

  useEffect(() => {
    setPersistentSelectedVehicleGroups(selectedVehicleGroups);
  }, [selectedVehicleGroups, setPersistentSelectedVehicleGroups]);

  useEffect(() => {
    setPersistentSelectedMainCategories(selectedMainCategories);
  }, [selectedMainCategories, setPersistentSelectedMainCategories]);

  useEffect(() => {
    setPersistentSelectedSubCategories(selectedSubCategories);
  }, [selectedSubCategories, setPersistentSelectedSubCategories]);

  useEffect(() => {
    setPersistentSelectedRoadTypes(selectedRoadTypes);
  }, [selectedRoadTypes, setPersistentSelectedRoadTypes]);

  return (
    <VehicleStateContext.Provider value={state}>
      <VehicleDispatchContext.Provider value={dispatch}>
        {children}
      </VehicleDispatchContext.Provider>
    </VehicleStateContext.Provider>
  );
};

export const useVehicleState = (): IVehicleState => {
  const context = useContext(VehicleStateContext);
  if (context === undefined) {
    throw new Error("useVehicleState must be used within a VehicleProvider");
  }
  return context;
};

export const useVehicleDispatch = (): Dispatch<Action> => {
  const context = useContext(VehicleDispatchContext);
  if (context === undefined) {
    throw new Error("useVehicleDispatch must be used within a VehicleProvider");
  }
  return context;
};
