import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import { getProjects } from "../api/projectApi";
import IProject from "../api/types/project";
import Action from "../types/action";
import {
  SET_PROJECTS, SET_PROJECT_SEARCH_TEXT, SET_SELECTED_PROJECT,
} from "./actions/projectActions";
import { useAuthState } from "./authContext";
import IProjectState from "./types/projectState";

const initialState: IProjectState = {
  projects: [],
  searchText: '',
  selectedProject: null,
};

const ProjectStateContext = createContext<IProjectState>(initialState);
const ProjectDispatchContext = createContext<Dispatch<Action>>(() => { });

const projectReducer = (
  state: IProjectState,
  action: Action
): IProjectState => {
  switch (action.type) {
    case SET_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
      };
    }
    case SET_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
        searchText: (action.payload as IProject)?.projectName || state.searchText,
      };
    }
    case SET_PROJECT_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const ProjectProvider = ({ children }: { children: JSX.Element }) => {

  const [state, dispatch] = useReducer(projectReducer, {
    projects: [],
    searchText: '',
    selectedProject: null,
  });
  const { account, getProjectAccessToken: getAccessToken } = useAuthState();

  // Fetch project list
  useEffect(() => {
    if (account) {
      const fetchProjects = async () => {
        const projectGroups = await getProjects(getAccessToken);
        if (projectGroups) {
          dispatch({
            type: SET_PROJECTS,
            payload: projectGroups,
          });
        }
      };

      fetchProjects();
    }
  }, [account, getAccessToken]);

  return (
    <ProjectStateContext.Provider value={state}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectStateContext.Provider>
  );
};

export const useProjectState = (): IProjectState => {
  const context = useContext(ProjectStateContext);
  if (context === undefined) {
    throw new Error("useProjectState must be used within a projectProvider");
  }
  return context;
};

export const useProjectDispatch = (): Dispatch<Action> => {
  const context = useContext(ProjectDispatchContext);
  if (context === undefined) {
    throw new Error("useProjectDispatch must be used within a projectProvider");
  }
  return context;
};
