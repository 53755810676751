import IMainCategory from "../api/types/mainCategory";
import ISubCategory from "../api/types/subCategory";
import IVehicleGroup from "../api/types/vehicleGroup";
import { getMainCategories, getSubCategories } from "./array";

export const computeMainCategory = (
  newCats: IVehicleGroup[],
  oldCats: IVehicleGroup[],
  oldMain: IMainCategory[]
) => {
  const mainCategories = getMainCategories(newCats);
  // Get the categories that weren't selected before so we can select them
  const newVehCats = newCats.filter(g => !oldCats.some(p => p.value === g.value)
  ) || [];
  const selectedMainCategories = [
    ...getMainCategories(newVehCats),
    ...oldMain,
  ].filter(s => mainCategories.some(m => s.value === m.value));
  return {
    mainCategories,
    selectedMainCategories,
  };
};
export const computeSubcategory = (
  newCats: IMainCategory[],
  oldCats: IMainCategory[],
  oldSubs: ISubCategory[]
) => {
  const subCategories = getSubCategories(newCats);
  const newSubCats = newCats.filter(g => !oldCats.some(p => p.value === g.value)
  ) || [];
  const selectedSubCategories = [
    ...getSubCategories(newSubCats),
    ...oldSubs,
  ].filter(s => subCategories.some(m => s.value === m.value));
  return {
    subCategories,
    selectedSubCategories,
  };
};
