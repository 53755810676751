import React, { useMemo } from "react";
import { Card, makeStyles, Theme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import { useProjectState } from "../../../../context/projectContext";
import { useTableSelect } from "../../../../hooks/useTableSelect";

const useStyles = makeStyles((theme: Theme) => ({
  vehicleRow: {
    cursor: "pointer",
  },
  regNoCell: {
    width: "8em",
  },
  missingValue: {
    color: "#757575",
  },
}));

export const ProjectsTable = () => {
  const { projects, searchText } = useProjectState();
  const { selectProject } = useTableSelect();

  const filteredProjects = useMemo(() => {
    return projects.filter((project) => {
      return (
        project.projectName.toLowerCase().includes(searchText.toLowerCase()) ||
        project.id.toString()
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
      );
    });
  }, [searchText, projects]);

  const classes = useStyles();

  return (
    <Card>
      <Table aria-label="Liste over prosjekter">
        <TableHead>
          <TableRow>
            <TableCell>Prosjektnr.</TableCell>
            <TableCell>Navn</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredProjects.map((project, index) => {
            return (
              <TableRow
                hover
                key={index}
                className={classes.vehicleRow}
                onClick={() => selectProject(project)}
              >
                <TableCell
                  className={clsx(classes.regNoCell, {
                  })}
                >
                  {project.id}
                </TableCell>
                <TableCell>
                  {project.projectName}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

