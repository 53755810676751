import React, { useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { useVehicleState } from "../../../context/vehicleContext";
import GoogleMapView from "./googleMapView";
import MapPointerWrapper from "./mapPointerWrapper";
import VehicleMapPointer from "./vehicle/vehicleMapPointer";
import { useProjectState } from "../../../context/projectContext";
import { ProjectMapPointer } from "./project/projectMapPointer";
import { useMachineState } from "../../../context/machineContext";
import { MachineMapPointer } from "./machine/MachineMapPointer";
import { useMapSelect } from '../../../hooks/useMapSelect';
import { sortBySelected } from '../../../functions/array';

const useStyles = makeStyles((theme: Theme) => ({
  map: {
    height: "100%",
    width: "100%",
  },
}));

export const MapView = () => {
  const style = useStyles();
  const {
    vehicles,
    selectedVehicle,
    selectedVehicleGroups,
    selectedMainCategories,
    selectedSubCategories,
    showRegNumber,
  } = useVehicleState();
  const {
    projects,
    selectedProject,
  } = useProjectState();
  const {
    machines,
    selectedMachine,
  } = useMachineState();
  const {
    selectMachine,
    selectProject,
    selectVehicle
  } = useMapSelect();

  const filteredVehicles = useMemo(() => {
    const items = vehicles.filter((vehicle) =>
      selectedVehicleGroups.some((vehicleGroup) => (
        vehicleGroup.value === vehicle.group
      )) && selectedMainCategories.some((mainCategory) => (
        mainCategory.value === vehicle.mainCategory
      )) && selectedSubCategories.some((subCategory) => (
        subCategory.value === vehicle.subCategory
      ))
    );
    if (selectedVehicle) {
      return items.sort(sortBySelected(selectedVehicle, 'vin'));
    }
    return items;
  }, [
    vehicles,
    selectedVehicle,
    selectedSubCategories,
    selectedVehicleGroups,
    selectedMainCategories,
  ]);

  const filteredMachines = useMemo(() => {
    const items = machines.filter((machine) =>
      selectedVehicleGroups.some((machineGroup) => (
        machineGroup.value === 'machine'
      )) && selectedMainCategories.some((mainCategory) => (
        mainCategory.value === machine.mainCategoryName
      )) && selectedSubCategories.some((subCategory) => (
        subCategory.value === machine.subCategoryName
      ))
    );
    if (selectedMachine) {
      return items.sort(sortBySelected(selectedMachine, 'internalNumber'));
    }
    return items;
  }, [
    machines,
    selectedMachine,
    selectedSubCategories,
    selectedVehicleGroups,
    selectedMainCategories,
  ]);

  const filteredProjects = useMemo(() => {
    if (!selectedVehicleGroups.some(v => v.value === 'project')) return [];
    if (selectedProject) {
      return projects.sort(sortBySelected(selectedProject, 'id'));
    }
    return projects;
  }, [projects, selectedProject, selectedVehicleGroups])

  // ========
  // Pointers
  // ========

  const vehiclePointers = useMemo(() => filteredVehicles.map((v, index) => {
    if (v.position !== null) {
      return (
        <MapPointerWrapper
          key={index}
          lat={v.position.latitude}
          lng={v.position.longitude}
          text={v.nickname ?? v.registrationNumber ?? v.vin}
        >
          <VehicleMapPointer
            heading={v.position.heading}
            vehicle={v}
            showRegNumber={showRegNumber}
            selected={
              !!selectedVehicle
              && v.vin === selectedVehicle.vin
            }
            onClick={selectVehicle}
          />
        </MapPointerWrapper>
      );
    }
    return null;
  }), [filteredVehicles, selectVehicle, selectedVehicle, showRegNumber])


  const machinePointers = useMemo(() => filteredMachines.map(m => (
    <MapPointerWrapper
      key={m.internalNumber}
      lat={parseFloat(m.position.latitude.replace(',', '.'))}
      lng={parseFloat(m.position.longitude.replace(',', '.'))}
      text={m.subCategoryName}
    >
      <MachineMapPointer
        machine={m}
        showId={showRegNumber}
        selected={
          !!selectedMachine
          && m.internalNumber === selectedMachine.internalNumber
        }
        onClick={selectMachine}
      />
    </MapPointerWrapper>
  )), [filteredMachines, selectMachine, selectedMachine, showRegNumber]);

  const projectPointers = useMemo(() => filteredProjects.map(p => (
    <MapPointerWrapper
      key={p.id}
      lat={parseFloat(p.latitude.replace(',', '.'))}
      lng={parseFloat(p.longitude.replace(',', '.'))}
      text={p.projectName}
    >
      <ProjectMapPointer
        project={p}
        showId={showRegNumber}
        selected={!!selectedProject && p.id === selectedProject.id}
        onClick={selectProject}
      />
    </MapPointerWrapper>
  )), [filteredProjects, selectProject, selectedProject, showRegNumber]);

  const items = useMemo(() => {
    if (selectedMachine) {
      return [
        ...projectPointers,
        ...vehiclePointers,
        ...machinePointers,
      ];
    }
    if (selectedProject) {
      return [
        ...machinePointers,
        ...vehiclePointers,
        ...projectPointers,
      ];
    }
    if (selectedVehicle) {
      return [
        ...machinePointers,
        ...projectPointers,
        ...vehiclePointers,
      ];
    }
    return [
      ...machinePointers,
      ...projectPointers,
      ...vehiclePointers,
    ];
  }, [
    selectedMachine,
    selectedProject,
    selectedVehicle,
    machinePointers,
    projectPointers,
    vehiclePointers,
  ]);

  return (
    <div className={style.map}>
      <GoogleMapView>
        {items}
      </GoogleMapView>
    </div>
  );
};
