import React from "react";
import {
  createStyles,
  LinearProgress,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { format } from "date-fns";
import { toDate } from '../functions/dates';

const useStyles = makeStyles({
  titleValue: {
    flex: 1,
    padding: "0.5em",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#757575",
  },
  value: {
    fontSize: "1.2em",
    display: "flex",
    alignItems: "center",
  },

  icon: {
    marginLeft: "0.25em",
  },

  placeholder: {
    color: "#757575",
  },
  secondary: {
    fontSize: "0.8rem",
    color: "#757575",
  },
});

const BorderLinearProgress = withStyles(() =>
  createStyles({
    root: {
      marginTop: "5px",
      marginBottom: "3px",
      paddingTop: "15px",
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1b628e",
    },
  })
)(LinearProgress);

export const TitleValue = ({
  title,
  value,
  suffix,
  progress,
  secondary,
  icon,
  type = "text",
}: {
  title: string;
  value: string | Number | null | undefined;
  suffix?: string | null;
  progress?: number;
  secondary?: boolean;
  icon?: JSX.Element;
  type?: 'text' | 'phone' | 'email' | 'date' | 'time';
}) => {
  const classes = useStyles();
  let t: 'placeholder' | typeof type = type;
  let val = `${value} ${suffix || ''}`;
  if (!value) {
    t = 'placeholder';
  } else if (type === 'date' || type === 'time') {
    const dateVal = toDate(value);
    if (!dateVal) {
      t = 'placeholder';
    } else if (type === 'time') {
      val = format(dateVal, "dd.MM.yyyy 'kl.' HH:mm");
    } else if (type === 'date') {
      val = format(dateVal, "dd.MM.yyyy");
    }
  }

  return (
    <div className={classes.titleValue}>
      <div className={classes.title}>{title}</div>
      {progress !== undefined && (
        <BorderLinearProgress variant="determinate" value={progress} />
      )}
      <div
        className={clsx(classes.value, {
          [classes.placeholder]: t === 'placeholder',
          [classes.secondary]: secondary,
        })}
      >
        {t === 'phone' && <a href={"tel:" + val}>{val}</a>}
        {t === 'email' && <a href={"mailto:" + val}>{val}</a>}
        {t === 'date' && val}
        {t === 'time' && val}
        {t === 'text' && val}
        {t === 'placeholder' && "Ukjent"}
        {icon && <span className={classes.icon}> {icon}</span>}
      </div>
    </div>
  );
};

export default TitleValue;
