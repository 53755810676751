import React, { useEffect, useState, useMemo } from "react";

import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import IVehicle from "../../../api/types/vehicle";
import CardHeader from "../../../components/cardHeader";
import { Info, Update } from "@material-ui/icons";
import { formatDuration, parseISO } from "date-fns";
import { nb } from "date-fns/locale";
import { Alert } from "@material-ui/lab";
import TitleValueRow from "../../../components/titleValueRow";
import TitleValue from "../../../components/titleValue";

const useStyles = makeStyles({
  card: {
    margin: "1em",
  },
  root: {
    flexGrow: 1,
  },
  barSpacing: {
    paddingTop: "5px",
    paddingBottom: "15px",
  },
});

const calculateTimeLeftToDriveDayPercentage = (
  timeLeftToDriveUntilDailyRest: number | null,
  weeklyDrivingExtensionInUse: boolean | null
): number => {
  if (timeLeftToDriveUntilDailyRest === null) {
    return 0;
  }
  if (weeklyDrivingExtensionInUse) {
    return Math.round(((600 - timeLeftToDriveUntilDailyRest) / 600) * 100);
  }
  return Math.round(((540 - timeLeftToDriveUntilDailyRest) / 540) * 100);
};

export const DrivingTimeCard = ({ vehicle }: { vehicle: IVehicle }) => {
  const classes = useStyles();

  const [timeLeftToDriveUntilBreakString, setTimeLeftToDriveUntilBreakString] =
    useState<string | null>();

  const [
    timeLeftToDriveUntilBreakPercentage,
    setTimeLeftToDriveUntilBreakPercentage,
  ] = useState<number>(0);

  const [
    timeLeftToDriveUntilDailyRestString,
    setTimeLeftToDriveUntilDailyRestString,
  ] = useState<string | null>();

  const [
    timeLeftToDriveUntilDailyRestPercentage,
    setTimeLeftToDriveUntilDailyRestPercentage,
  ] = useState<number>(0);

  const timeLeftToDriveCurrentWeekString = useMemo(() => {
    if (vehicle.driverTimes?.timeLeftToDriveCurrentWeek) {
      var hours = vehicle.driverTimes.timeLeftToDriveCurrentWeek / 60;
      var rHours = Math.floor(hours);
      var minutes = (hours - rHours) * 60;
      var rMinutes = Math.round(minutes);
      return formatDuration(
        { hours: rHours, minutes: rMinutes },
        { locale: nb, delimiter: " og " }
      );
    }
    return null;
  }, [vehicle.driverTimes?.timeLeftToDriveCurrentWeek]);

  const timeLeftToDriveNextWeekString = useMemo(() => {
    if (vehicle.driverTimes?.timeLeftToDriveNextWeek) {
      var hours = vehicle.driverTimes.timeLeftToDriveNextWeek / 60;
      var rHours = Math.floor(hours);
      var minutes = (hours - rHours) * 60;
      var rMinutes = Math.round(minutes);

      return formatDuration(
        { hours: rHours, minutes: rMinutes },
        { locale: nb, delimiter: " og " }
      );
    }
    return null;
  }, [vehicle.driverTimes?.timeLeftToDriveNextWeek]);

  useEffect(() => {
    if (vehicle.driverTimes?.timeLeftToDriveUntilBreak) {
      var hours = vehicle.driverTimes.timeLeftToDriveUntilBreak / 60;
      var rHours = Math.floor(hours);
      var minutes = (hours - rHours) * 60;
      var rMinutes = Math.round(minutes);

      setTimeLeftToDriveUntilBreakString(
        formatDuration(
          { hours: rHours, minutes: rMinutes },
          { locale: nb, delimiter: " og " }
        )
      );

      setTimeLeftToDriveUntilBreakPercentage(
        Math.round(
          ((270 - vehicle.driverTimes.timeLeftToDriveUntilBreak) / 270) * 100
        )
      );
    } else {
      setTimeLeftToDriveUntilBreakString(null);
      setTimeLeftToDriveUntilBreakPercentage(0);
    }
  }, [vehicle.driverTimes?.timeLeftToDriveUntilBreak]);

  useEffect(() => {
    if (vehicle.driverTimes?.timeLeftToDriveUntilDailyRest) {
      var hours = vehicle.driverTimes.timeLeftToDriveUntilDailyRest / 60;
      var rHours = Math.floor(hours);
      var minutes = (hours - rHours) * 60;
      var rMinutes = Math.round(minutes);

      setTimeLeftToDriveUntilDailyRestString(
        formatDuration(
          { hours: rHours, minutes: rMinutes },
          { locale: nb, delimiter: " og " }
        )
      );

      setTimeLeftToDriveUntilDailyRestPercentage(
        calculateTimeLeftToDriveDayPercentage(
          vehicle.driverTimes?.timeLeftToDriveUntilDailyRest,
          vehicle.driverTimes?.weeklyDrivingExtensionInUse
        )
      );
    } else {
      setTimeLeftToDriveUntilDailyRestString(null);
      setTimeLeftToDriveUntilDailyRestPercentage(0);
    }
  }, [vehicle.driverTimes?.timeLeftToDriveUntilDailyRest, vehicle.driverTimes?.weeklyDrivingExtensionInUse]);

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        icon={<Info />}
        title="Kjøretid"
        dateTime={
          vehicle.driverTimes?.updatedDateTime
            ? parseISO(vehicle.driverTimes?.updatedDateTime)
            : null
        }
      />
      <CardContent>
        {vehicle.driverTimes ? (
          <>
            <TitleValueRow>
              <TitleValue
                title="Gjenværende kjøretid før pause"
                value={timeLeftToDriveUntilBreakString}
                progress={timeLeftToDriveUntilBreakPercentage}
                secondary={true}
              />
            </TitleValueRow>
            <TitleValueRow>
              <TitleValue
                title="Gjenværende kjøretid før døgnhvil"
                value={timeLeftToDriveUntilDailyRestString}
                progress={timeLeftToDriveUntilDailyRestPercentage}
                secondary={true}
                icon={
                  vehicle.driverTimes.weeklyDrivingExtensionInUse ? (
                    <Update color="primary" fontSize="small" />
                  ) : undefined
                }
              />
            </TitleValueRow>
            <TitleValueRow>
              <TitleValue
                title="Gjenværende kjøretid denne uken"
                value={timeLeftToDriveCurrentWeekString}
              />
            </TitleValueRow>
            <TitleValueRow>
              <TitleValue
                title="Gjenværende kjøretid neste uke"
                value={timeLeftToDriveNextWeekString}
              />
            </TitleValueRow>
          </>
        ) : (
          <Alert severity="info">Ingen data for kjøretid tilgjengelig...</Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default DrivingTimeCard;
