import { isValid } from 'date-fns';

/**
 * Check if Date, DateTime, or anything else is a valid date
 * @param date Date to check
 * @returns True if date is valid, false otherwise
 */
export const isValidDate = (date: any) => {
  if (typeof date === 'string') {
    return date && isValid(new Date(date));
  }
  if (date instanceof Date) {
    return isValid(date);
  }
  return false;
}

/**
 * Tries to convert any value to a date object
 * @param date Date candidate
 * @returns Date object or null if not able to convert to date
 */
export const toDate = (date: any): Date|null => {
  if (!isValidDate(date)) return null;
  if (typeof date === 'string') {
    return new Date(date);
  }
  return date;
}
