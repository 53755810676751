import { IMachine } from "../api/types/machine";
import IProject from "../api/types/project";
import IVehicle from "../api/types/vehicle";
import { SET_MACHINE_SEARCH_TEXT, SET_SELECTED_MACHINE } from "../context/actions/machineActions";
import { SET_PROJECT_SEARCH_TEXT, SET_SELECTED_PROJECT } from "../context/actions/projectActions";
import { SET_IS_SIDESHEET_OPEN } from "../context/actions/sidesheetActions";
import { SET_SEARCH_TEXT, SET_SELECTED_VEHICLE } from "../context/actions/vehicleActions";
import { useMachineDispatch } from "../context/machineContext";
import { useProjectDispatch } from "../context/projectContext";
import { useSidesheetDispatch } from "../context/sidesheetContext";
import { useVehicleDispatch } from "../context/vehicleContext";
import { useSwitchTab } from "./useSwitchTab";

export const useMapSelect = () => {
  const sidesheetDispatch = useSidesheetDispatch();
  const projectDispatch = useProjectDispatch();
  const vehicleDispatch = useVehicleDispatch();
  const machineDispatch = useMachineDispatch();
  const switchTab = useSwitchTab();

  const setSelected = (vehicle: IVehicle | null, project: IProject | null, machine: IMachine | null) => {
    projectDispatch({ type: SET_SELECTED_PROJECT, payload: project });
    vehicleDispatch({ type: SET_SELECTED_VEHICLE, payload: vehicle });
    machineDispatch({ type: SET_SELECTED_MACHINE, payload: machine });
    sidesheetDispatch({ type: SET_IS_SIDESHEET_OPEN, payload: true });
  }

  const selectVehicle = (vehicle: IVehicle) => {
    setSelected(vehicle, null, null);
    switchTab('vehicle');
    projectDispatch({ type: SET_PROJECT_SEARCH_TEXT, payload: '' });
    machineDispatch({ type: SET_MACHINE_SEARCH_TEXT, payload: '' });
  }

  const selectProject = (project: IProject) => {
    setSelected(null, project, null);
    switchTab('project');
    vehicleDispatch({ type: SET_SEARCH_TEXT, payload: '' });
    machineDispatch({ type: SET_MACHINE_SEARCH_TEXT, payload: '' });
  }

  const selectMachine = (machine: IMachine) => {
    setSelected(null, null, machine);
    switchTab('machine');
    vehicleDispatch({ type: SET_SEARCH_TEXT, payload: '' });
    projectDispatch({ type: SET_PROJECT_SEARCH_TEXT, payload: '' });
  }


  return { selectVehicle, selectProject, selectMachine };
}
