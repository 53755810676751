import React, { useMemo } from "react";
import { useVehicleState } from "../../../../context/vehicleContext";
import { Card, makeStyles, Theme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import { useTableSelect } from "../../../../hooks/useTableSelect";

const useStyles = makeStyles((theme: Theme) => ({
  vehicleRow: {
    cursor: "pointer",
  },
  regNoCell: {
    width: "8em",
  },
  missingValue: {
    color: "#757575",
  },
}));

export const VehiclesTable = () => {
  const { vehicles, searchText } = useVehicleState();
  const { selectVehicle } = useTableSelect();


  const filteredVehicles = useMemo(() => {
    return vehicles.filter((vehicle) => {
      return (
        vehicle.vin.toLowerCase().includes(searchText.toLowerCase()) ||
        vehicle.registrationNumber
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        vehicle.nickname?.toLowerCase().includes(searchText.toLowerCase())
      );
    });
  }, [searchText, vehicles]);

  const classes = useStyles();

  return (
    <Card>
      <Table aria-label="Liste over kjøretøy">
        <TableHead>
          <TableRow>
            <TableCell>Navn</TableCell>
            <TableCell>Registreringsnummer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredVehicles.map((vehicle, index) => {
            return (
              <TableRow
                hover
                key={index}
                className={classes.vehicleRow}
                onClick={() => selectVehicle(vehicle)}
              >
                <TableCell>
                  {vehicle.nickname ? vehicle.nickname : vehicle.vin}
                </TableCell>
                <TableCell
                  className={clsx(classes.regNoCell, {
                    [classes.missingValue]: !vehicle.registrationNumber,
                  })}
                >
                  {vehicle.registrationNumber
                    ? vehicle.registrationNumber
                    : "Ukjent"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};

export default VehiclesTable;
