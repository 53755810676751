import React, {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  useEffect,
} from "react";
import Action from "../types/action";
import useLocalStorage from "../utility/useLocalStorage";
import {
  SET_CENTER,
  SET_ZOOM,
  SET_BOUNDS,
  SET_MAP_TYPE_ID,
} from "./actions/mapActions";
import IMapState from "./types/mapState";

const initialState: IMapState = {
  center: { lat: 58.7829166, lng: 5.8802798 },
  zoom: 10,
  defaultCenter: { lat: 58.7829166, lng: 5.8802798 },
  defaultZoom: 10,
  bounds: null,
  mapTypeId: "roadmap",
};

const MapStateContext = createContext<IMapState>(initialState);
const MapDispatchContext = createContext<Dispatch<Action>>(() => {});

const mapReducer = (state: IMapState, action: Action): IMapState => {
  switch (action.type) {
    case SET_CENTER: {
      return {
        ...state,
        center: action.payload,
      };
    }
    case SET_ZOOM: {
      return {
        ...state,
        zoom: action.payload,
      };
    }
    case SET_BOUNDS: {
      return {
        ...state,
        bounds: action.payload,
      };
    }
    case SET_MAP_TYPE_ID: {
      return {
        ...state,
        mapTypeId: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const MapProvider = ({ children }: { children: JSX.Element }) => {
  const [persistentCenter, setPersistentCenter] = useLocalStorage(
    "map-center",
    {
      lat: 58.7829166,
      lng: 5.8802798,
    }
  );

  const [persistentZoom, setPersistentZoom] = useLocalStorage("map-zoom", 10);

  const [persistentMapTypeId, setPersistentMapTypeId] = useLocalStorage(
    "map-type-id",
    "roadmap"
  );

  const [state, dispatch] = useReducer(mapReducer, {
    center: persistentCenter,
    zoom: persistentZoom,
    defaultCenter: { lat: 58.8891287, lng: 5.9587767 },
    defaultZoom: 10,
    bounds: null,
    mapTypeId: persistentMapTypeId,
  });

  useEffect(() => {
    setPersistentCenter(state.center);
  }, [setPersistentCenter, state.center]);

  useEffect(() => {
    setPersistentZoom(state.zoom);
  }, [setPersistentZoom, state.zoom]);

  useEffect(() => {
    setPersistentMapTypeId(state.mapTypeId);
  }, [setPersistentMapTypeId, state.mapTypeId]);

  return (
    <MapStateContext.Provider value={state}>
      <MapDispatchContext.Provider value={dispatch}>
        {children}
      </MapDispatchContext.Provider>
    </MapStateContext.Provider>
  );
};

export const useMapState = (): IMapState => {
  const context = useContext(MapStateContext);
  if (context === undefined) {
    throw new Error("useMapState must be used within a MapProvider");
  }
  return context;
};

export const useMapDispatch = (): Dispatch<Action> => {
  const context = useContext(MapDispatchContext);
  if (context === undefined) {
    throw new Error("useMapDispatch must be used within a MapProvider");
  }
  return context;
};
