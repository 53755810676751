import React from "react";
import { Redirect } from "react-router-dom";
import { useAuthState } from "../../context/authContext";

export const LoginRedirect = () => {
  const { account } = useAuthState();

  if (account) {
    return <Redirect to="/" />;
  }

  return <div>Redirecting...</div>;
};

export default LoginRedirect;
