import React from "react";

import { useProjectState } from "../../../../context/projectContext";
import { ProjectDataCard } from "./projectDataCard";
import { ProjectEmployeeCard } from "./projectEmployeeCard";

export const ProjectInfo = () => {
  const {
    selectedProject,
  } = useProjectState();

  if (!selectedProject) {
    return <span>Ingen kjøretøy valgt</span>;
  }

  const resp = selectedProject.responsible || null;
  const opsMan = selectedProject.operationsManager || null;

  return (
    <>
      <ProjectDataCard project={selectedProject} />
      {resp && (
        <ProjectEmployeeCard
          title="Ansvarlig"
          name={resp.fullName}
          phone={resp.mobilePhoneWork || ''}
          email={resp.email}
        />
      )}
      {opsMan && (
        <ProjectEmployeeCard
          title="Driftsleder"
          name={opsMan.fullName}
          phone={opsMan.mobilePhoneWork || ''}
          email={opsMan.email}
        />
      )}
    </>
  );
};
