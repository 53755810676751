import { IMachine } from "../api/types/machine";
import IProject from "../api/types/project";
import IVehicle from "../api/types/vehicle";
import { SET_MACHINE_SEARCH_TEXT, SET_SELECTED_MACHINE } from "../context/actions/machineActions";
import { SET_PROJECT_SEARCH_TEXT, SET_SELECTED_PROJECT } from "../context/actions/projectActions";
import { SET_SEARCH_TEXT, SET_SELECTED_VEHICLE, SET_SELECTED_VEHICLE_GROUPS, SET_SELECTED_MAIN_CATEGORIES, SET_SELECTED_SUB_CATEGORIES } from '../context/actions/vehicleActions';
import { useMachineDispatch } from "../context/machineContext";
import { useProjectDispatch } from "../context/projectContext";
import { useVehicleDispatch, useVehicleState } from "../context/vehicleContext";
import { useMapDispatch } from '../context/mapContext';
import { SET_CENTER, SET_ZOOM } from '../context/actions/mapActions';
import IVehicleGroup from '../api/types/vehicleGroup';
import IMainCategory from '../api/types/mainCategory';
import ISubCategory from '../api/types/subCategory';

export const useTableSelect = () => {
  const {
    vehicleGroups,
    selectedVehicleGroups,
    mainCategories,
    selectedMainCategories,
    subCategories,
    selectedSubCategories,
  } = useVehicleState();
  const projectDispatch = useProjectDispatch();
  const vehicleDispatch = useVehicleDispatch();
  const machineDispatch = useMachineDispatch();
  const mapDispatch = useMapDispatch();

  const setSelected = (vehicle: IVehicle | null, project: IProject | null, machine: IMachine | null) => {
    projectDispatch({ type: SET_SELECTED_PROJECT, payload: project });
    vehicleDispatch({ type: SET_SELECTED_VEHICLE, payload: vehicle });
    machineDispatch({ type: SET_SELECTED_MACHINE, payload: machine });
  }

  /**
   * Turn on a specified category
   * @param all All selectable categories
   * @param selected Currently selected categories
   * @param cat Category to turn on
   * @param action Action to perform to turn on
   * @returns True if category was successfully set, false otherwise
   */
  const turnOn = <T extends IVehicleGroup | IMainCategory | ISubCategory>(
    all: T[],
    selected: T[],
    cat: string | undefined,
    action: string
  ) => {
    if (!cat) return false;
    if (selected.some(m => m.value === cat)) return false;
    const newCat = all.find(m => m.value === cat);
    if (!newCat) return false;
    vehicleDispatch({ type: action, payload: [...selected, newCat] });
    return true;
  }

  const ensureEnabled = (cats: { group: 'machine' | 'project' | 'vehicle', main?: string, sub?: string }) => {
    // Only run the lower categories if top ones don't get applied
    turnOn(vehicleGroups, selectedVehicleGroups, cats.group, SET_SELECTED_VEHICLE_GROUPS)
      || turnOn(mainCategories, selectedMainCategories, cats.main, SET_SELECTED_MAIN_CATEGORIES)
      || turnOn(subCategories, selectedSubCategories, cats.sub, SET_SELECTED_SUB_CATEGORIES);
  }

  const mapFocus = (lat: number, lng: number) => {
    mapDispatch({
      type: SET_CENTER,
      payload: {
        lat,
        lng,
      },
    });
    mapDispatch({
      type: SET_ZOOM,
      payload: 15,
    });
  }

  const selectVehicle = (vehicle: IVehicle) => {
    ensureEnabled({
      group: 'vehicle',
      main: vehicle.mainCategory,
      sub: vehicle.subCategory,
    });
    setSelected(vehicle, null, null);
    projectDispatch({ type: SET_PROJECT_SEARCH_TEXT, payload: '' });
    machineDispatch({ type: SET_MACHINE_SEARCH_TEXT, payload: '' });
    if (vehicle.position) {
      mapFocus(
        vehicle.position.latitude,
        vehicle.position.longitude
      );
    }
  }

  const selectProject = (project: IProject) => {
    ensureEnabled({
      group: 'project',
    });
    setSelected(null, project, null);
    vehicleDispatch({ type: SET_SEARCH_TEXT, payload: '' });
    machineDispatch({ type: SET_MACHINE_SEARCH_TEXT, payload: '' });
    mapFocus(
      parseFloat(project.latitude.replace(',', '.')),
      parseFloat(project.longitude.replace(',', '.'))
    );
  }

  const selectMachine = (machine: IMachine) => {
    ensureEnabled({
      group: 'machine',
      main: machine.mainCategoryName,
      sub: machine.subCategoryName,
    });
    setSelected(null, null, machine);
    vehicleDispatch({ type: SET_SEARCH_TEXT, payload: '' });
    projectDispatch({ type: SET_PROJECT_SEARCH_TEXT, payload: '' });
    mapFocus(
      parseFloat(machine.position.latitude.replace(',', '.')),
      parseFloat(machine.position.longitude.replace(',', '.'))
    );
  }


  return { selectVehicle, selectProject, selectMachine };
}
