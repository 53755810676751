import React from "react";
import { Typography, makeStyles, Divider } from "@material-ui/core";
import { subHours, formatDistance } from "date-fns";
import { nb } from "date-fns/esm/locale";
import clsx from "clsx";

const useStyles = makeStyles({
  cardHeader: {
    padding: "1em",
    display: "flex",
    alignItems: "center",
  },
  cardHeaderIcon: {
    marginRight: "0.5em",
    display: "flex",
    alignItems: "center",
  },
  cardHeaderTitle: {
    flex: 1,
  },
  cardHeaderClock: {
    color: "grey",
  },
  warningText: {
    color: "#ffb266",
  },
});

export const CardHeader = ({
  icon,
  title,
  dateTime,
}: {
  icon: JSX.Element;
  title: string;
  dateTime?: Date | null | undefined;
}) => {
  const classes = useStyles();

  const isDateTimeOld = (date: Date): boolean => {
    const threshold = subHours(new Date(), 1);
    return date < threshold;
  };

  return (
    <>
      <div className={classes.cardHeader}>
        <div className={classes.cardHeaderIcon}>{icon}</div>
        <Typography className={classes.cardHeaderTitle}>{title}</Typography>
        {dateTime && (
          <Typography
            variant="caption"
            className={clsx(classes.cardHeaderClock, {
              [classes.warningText]: isDateTimeOld(dateTime),
            })}
          >
            {`${formatDistance(new Date(), dateTime, { locale: nb })} siden`}
          </Typography>
        )}
      </div>
      <Divider />
    </>
  );
};

export default CardHeader;
