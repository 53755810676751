import axios from "axios";

import { IMachine } from "./types/machine";

const client = axios.create({
  baseURL: process.env.REACT_APP_MASKIN_API_BASE_URL,
  responseType: "json",
});

export const getMachines = async (
  getAccessToken: () => Promise<string | null>
): Promise<IMachine[] | null> => {
  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      throw new Error("Unable to get access token");
    }

    const response = await client.get<IMachine[]>("/api/v1.0/machines", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data.filter(d => (
      d.position
      && d.position.latitude
      && d.position.longitude
      && parseFloat(d.position.latitude.replace(',', '.'))
      && parseFloat(d.position.longitude.replace(',', '.'))
    ));
  } catch (error) {
    console.error("getAppConfig", error);
    return null;
  }
};
