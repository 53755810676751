import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  cardContent: {
    paddingTop: "15px",
  },
});

export const CardContentInfo = ({
  contentHead,
  contentValue,
}: {
  contentHead: string;
  contentValue: string | JSX.Element | null | undefined;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContent}>
      <Typography gutterBottom variant="body1">
        {contentHead}
      </Typography>
      {contentValue}
    </div>
  );
};

export default CardContentInfo;
