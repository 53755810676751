import React, { createContext, useContext, useReducer, Dispatch } from "react";
import Action from "../types/action";
import {
  SET_IS_SIDESHEET_OPEN,
  SET_SELECTED_TAB,
  SET_SIDESHEET_CONTENT,
} from "./actions/sidesheetActions";
import ISidesheetState from "./types/sidesheetState";

const initialState: ISidesheetState = {
  isOpen: false,
  content: null,
  selectedTab: 'vehicle',
};

const SidesheetStateContext = createContext<ISidesheetState>(initialState);
const SidesheetDispatchContext = createContext<Dispatch<Action>>(() => { });

const sidesheetReducer = (
  state: ISidesheetState,
  action: Action
): ISidesheetState => {
  switch (action.type) {
    case SET_IS_SIDESHEET_OPEN: {
      return {
        ...state,
        isOpen: action.payload,
      };
    }
    case SET_SIDESHEET_CONTENT: {
      return {
        ...state,
        content: action.payload,
      };
    }
    case SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const SidesheetProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(sidesheetReducer, initialState);

  return (
    <SidesheetStateContext.Provider value={state}>
      <SidesheetDispatchContext.Provider value={dispatch}>
        {children}
      </SidesheetDispatchContext.Provider>
    </SidesheetStateContext.Provider>
  );
};

export const useSidesheetState = (): ISidesheetState => {
  const context = useContext(SidesheetStateContext);
  if (context === undefined) {
    throw new Error(
      "useSidesheetState must be used within a SidesheetProvider"
    );
  }
  return context;
};

export const useSidesheetDispatch = (): Dispatch<Action> => {
  const context = useContext(SidesheetDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSidesheetDispatch must be used within a SidesheetProvider"
    );
  }
  return context;
};
