import IVehicle from '../api/types/vehicle';
import IVehicleGroup from '../api/types/vehicleGroup';
import { getIndex } from './array';

export const getVehicleCategory = (vehicles: IVehicle[]) => {
    const cat: IVehicleGroup = { name: 'Kjøretøy', value: 'vehicle', mainCategories: [] };
    for (let i = 0; i < vehicles.length; i += 1) {
      const vehicle = vehicles[i];

      // main categories
      if (!cat.mainCategories.some(mc => mc.value === vehicle.mainCategory)) {
        cat.mainCategories.push({ name: vehicle.mainCategoryName, value: vehicle.mainCategory, subCategories: [] });
      }
      const m = getIndex(cat.mainCategories, c => c.value === vehicle.mainCategory);
      if (m === null) continue;

      // subcategories
      const s = getIndex(cat.mainCategories[m].subCategories, c => c.value === vehicle.subCategory);
      if (s === null) {
        cat.mainCategories[m].subCategories.push({ name: vehicle.subCategoryName, value: vehicle.subCategory });
      }
    }
    return cat;
}
